.infoSectionTitle {
  color: var(--text-color-v3);
  font-size: 0.95rem;
  margin-bottom: 0.5rem;
}

.infoSectionText {
  color: var(--text-color-v2);
  font-size: 1.1rem;
  margin-bottom: 0.75rem;
  font-weight: 500;
}

.infoSectionStatus {
  color: var(--text-color-v3);
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.infoSectionStatus > img {
  margin-right: 0.5rem;
}

.infoSection:first-child {
  margin-top: 0;
}

.infoSection {
  margin-top: 3rem;
}

.btn {
  padding: 0.75rem 1rem;
  color: var(--text-color-v1);
  border: 1px solid var(--text-color-v4);
  background: white;
  font-size: 0.95rem;
  border-radius: 0.3rem;
}

.profileHeader {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.profileHeader > img {
  margin-right: 1.5rem;
}

.contentContainer {
  margin-left: 0px;
  width: 100%;
}
