.header {
  margin-bottom: 2rem;
}

.title {
  color: var(--text-color-v2);
  margin-bottom: 0.5rem;
}

.subTitle {
  color: var(--text-color-v2);
  font-size: 1.3rem;
  font-weight: 500;
}

.actionHeader {
  margin: 2rem 0;
  margin-bottom: 1.25rem;
}

.actionContainerDisabled {
  opacity: 0.65;
}

.actionTitle {
  font-size: 1.3rem;
  color: var(--text-color-v2);
  font-weight: 500;
}

.actionSubTitle {
  font-size: 1rem;
  color: var(--text-color-v2);
}

.noActionRequired {
  max-height: 150px;
}

.noActionRequired > ion-row:first-child {
  margin-bottom: 0;
}

.noActionRequired > ion-row:last-child {
  margin-top: 0;
}
