.notificationPopup {
}

.notificationPopup :global(.alert-wrapper) {
  padding: 0px 36px;
  min-width: 400px;
}

.notificationPopup :global(.alert-head) {
}

.notificationPopup :global(.alert-message) {
  font-size: 14px;
  color: #000;
  display: flex;
  gap: 36px;
  align-items: center;
  justify-content: center;
  line-height: 21px;
  padding-inline: 0;
}

.notificationPopup:global(.size-big) :global(.alert-message) {
  flex-direction: column;
  font-size: 17px;
  gap: 28px;
  padding: 0 24px 16px;
  text-align: center;
}

.notificationPopup :global(.alert-button-group) {
  padding-inline: 0;
}

.notificationPopup:global(.size-big) :global(.alert-button-group) {
  justify-content: center;
}

.notificationPopup :global(.alert-button) {
  margin-inline-end: 0;
}

.icon {
  width: 36px;
  height: 36px;
}

.notificationPopup:global(.size-big) .icon {
  width: 72px;
  height: 72px;
}

.alert {
}

.toast {
}

.info {
}

.dialog {
}

.dialog :global(.alert-wrapper) {
  min-width: 450px;
}

.dialog :global(.alert-message) {
  font-size: 17px;
}

.dialog :global(.alert-button-group) {
  justify-content: center;
  gap: 18px;
  flex-wrap: nowrap;
  margin-bottom: 28px;
}

.dialog :global(.alert-button) {
  border-radius: 8px;
  color: #ffffff;
  padding: 12px 14px;
  text-transform: initial;
  font-size: 16px;
  font-weight: 400;
}

.dialog :global(.alert-button.main) {
  background-color: #0029fc;
}

.dialog :global(.alert-button.secondary) {
  background-color: #b2b2b2;
}
