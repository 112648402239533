.transferStage {
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  padding: 1.75rem 2rem;
  font-size: 1.3rem;
}

.summaryContainer {
  padding: 1.75rem 2rem;
  border-bottom: 1px solid var(--text-color-v5);
  border-top: 1px solid var(--text-color-v5);
}

.footer {
  padding: 1.75rem 2rem;
  display: flex;
}

.actionBtnContainer {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.paydetailCol {
  padding-right: 10%;
}

.amountRow {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--text-color-v5);
  display: flex;
  align-items: center;
}

.feeRow {
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--text-color-v5);
  display: flex;
  align-items: center;
}

.totalsRow {
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
}

.infoBox {
  margin-bottom: 1rem;
}

.boxTitle {
  font-weight: 500;
  color: var(--text-color-v1);
  font-size: 0.95rem;
  margin-bottom: 0.5rem;
}

.boxSubTitle {
  font-weight: 500;
  color: var(--text-color-v1);
  font-size: 1rem;
  margin-bottom: 0.2rem;
}

.boxText {
  color: var(--text-color-v4);
}

.summaryAmountTitle,
.summaryTotalTitle {
  color: var(--text-color-v0);
  font-weight: 500;
  font-size: 1.1rem;
}

.summaryAmountValue,
.summaryTotalValue {
  color: var(--text-color-v1);
  font-weight: 500;
  font-size: 1.25rem;
}

.summaryTotalValue {
  font-size: 1.6rem;
}

.summaryFeeTitle,
.summaryFeeValue {
  color: var(--text-color-v4);
  font-weight: 400;
  font-size: 1rem;
}

.summaryAmountValue,
.summaryFeeValue,
.summaryTotalValue {
  text-align: right;
}

.paidAmountTitle,
.paidTotalTitle {
  color: var(--text-color-v4);
  font-size: 1rem;
}

.paidAmountValue,
.paidTotalValue {
  color: var(--text-color-v0);
  font-size: 1.1rem;
}

.paidTotalValue {
  font-size: 1.6rem;
}

.cancelPaymentBtn {
  color: var(--text-color-v3);
  background: initial;
  font-size: 1.1rem;
  padding: 0.5rem;
  cursor: pointer;
}
