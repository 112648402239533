.title {
  font-size: 1.3rem;
}

.formContainer {
  margin: 3rem 0 2rem 0;
}

.footer {
  margin-top: 3rem;
}

.actionsFooterSingleAction > ion-col {
  text-align: center;
}
