.form1 {
  padding: 2.5rem 2.5rem;
  border-radius: 0.5rem;
  background: white;
}

.btn {
  text-align: center;
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  font-size: 1.25rem;
}

.btn[disabled] {
  opacity: 0.45;
}

.submit-btn {
  color: white;
  background-color: var(--secondary-color-main);
  width: 100%;
}

.title {
  margin-bottom: 2rem;
  font-family: var(--primary-font-main);
  font-size: 1.3rem;
  font-weight: 300;
}

.formModalTitle {
  font-size: 1.5em;
  color: var(--text-color-v2);
  font-weight: 500;
  text-align: center;
  margin-bottom: initial;
  padding: calc(1.2rem + env(safe-area-inset-top)) 0 1.2rem;
  background: white;
  border-radius: 0.5rem 0.5rem 0 0;
  border-bottom: 1px solid var(--text-color-v5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-close-icon {
  position: absolute;
  z-index: 100;
  cursor: pointer;
  right: 1.2rem;
  font-size: 1.75rem;
}

.input-group {
  padding-bottom: 1rem;
}

.input-group-title {
  margin-bottom: 0.2rem;
  color: var(--text-color-v2);
  font-size: 1rem;
  font-weight: 500;
}

.input-grid-group {
  --ion-grid-padding: 0px;
}

.input-grid-group ion-col {
  padding-top: 0px;
  padding-bottom: 0px;
}

.input-grid-group ion-col:first-child {
  padding-left: 0px;
}

.input-grid-group ion-col:last-child {
  padding-right: 0px;
}

.input-item {
  margin-bottom: 1rem;
  --border-radius: 0.3rem;
  --border-width: 1px;
  --border-color: lightgray;
  --border-style: solid;
}

.input-item-checkbox {
  --border-radius: initial;
  --border-width: initial;
  --border-color: initial;
  --border-style: initial;
  --padding-start: 0;
  --padding-end: 0;
  --ripple-color: transparent;
  --background-hover: initial;
}

.input-item-checkbox ion-checkbox {
  margin-right: 0.75rem;
}

.input-item-checkbox ion-label {
  white-space: pre-wrap !important;
}

.input-item > ion-select {
  width: 100%;
}

.input-item.item-has-focus {
  --border-color: blue;
}

.input-label {
}

.vgs-input {
  display: block;
  width: 100%;
  height: 50px;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.vgs-input iframe {
  border: 0 none transparent;
  height: 100%;
  vertical-align: middle;
  width: 100%;
}

.vgs-input iframe input {
  font-size: 1.2rem;
}

.input {
}

.phone-number-item {
  overflow: visible;
  z-index: 100000000;
}

.phone-number-item .react-tel-input input {
  width: 100%;
  border: none !important;
  padding-left: 3.5rem;
  box-sizing: none;
}
