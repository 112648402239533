.disableHighlight {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none;

  border: none;
  background: inherit;
  width: 100%;
  cursor: pointer;
  outline: none;
}

.empty,
.empty > option[value=""] {
  color: gray;
}

.empty > option {
  color: initial;
}
