.menuIconContainer {
  margin-inline-end: 20px;
  margin-inline-start: 20px !important;
}

.menuHeader {
  margin-bottom: 3rem;
}

.menuLogo {
  max-width: 120px;
}

.dashboard-page {
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 0;
}

.dashboard-page-header {
  background: white;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  width: 100%;
  /*flex-basis: 100px;*/
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dashboard-page-content {
  background: white;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  margin-top: 1.75rem;
  padding-bottom: 4rem;
  height: 100%;
  width: 100%;
  overflow: auto;
  flex-grow: 1;
  flex-basis: calc(100% - 120px);
}

.dashboard-page-header-action-btn {
  padding: 1rem 1.5rem;
  background: var(--secondary-color-main);
  color: white;
  font-size: 1rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
}

.dashboard-page-header-recipients-action-btn {
  min-width: 266px !important;
}

.dashboard-page-header-add-action-action-btn {
  min-width: 209px !important;
}

.dashboard-page-header-action-btn > img {
  margin-right: 1.25rem;
}

.dashboard-page-header-search {
  --border-radius: 0.3rem;
  --border-width: 1px;
  --border-color: var(--text-color-v4);
  --border-style: solid;
}

.dashboard-page-header-search.item-has-focus {
  --border-color: var(--secondary-color-v3);
}

.dashboard-page-header-search > img {
  margin-right: 1rem;
  width: 1.5rem;
}

.settings-page {
}

.settings-tab-bar {
  background: white;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid var(--text-color-v5);
}

.settings-tab-bar > ion-segment-button {
  --color: var(--text-color-v3);
  --color-checked: var(--secondary-color-v3);
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 400;
}

.settings-tab-bar > * {
  max-width: 200px;
}

.settings-content {
  background: white;
  border-radius: 0px 0px 0.5rem 0.5rem;
  height: calc(100% - 50px);
}

.settings-content > .dashboard-page {
  padding: 2rem;
}

.settings-content > .dashboard-page {
  width: 100%;
  align-items: flex-start;
}

.transfer-stage-header {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.transfer-stage {
  padding: 16px;
}

.account-right-menu {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.account-transfer-button {
  margin-right: 2rem;
  padding: 0.2rem 1.75rem;
  background: var(--primary-color-main);
  border-radius: 0.4rem;

  padding-top: 0px;
  padding-bottom: 0px;
}

.account-transfer-button > img {
  height: 30px;
  width: auto;
}

.account-profile-btn {
  display: flex;
}

.account-avatar-icon {
  height: 40px;
  width: auto;
}

.account-header-name {
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--text-color-v1);
  margin: auto 0.5rem;
}


.dashboard-page-recipients-header,
.dashboard-page-transaction-history-header,
.dashboard-page-home-header {
  max-height: 120px;
  flex-basis: 120px;
}

.dashboard-page-home-header {
  max-height: 350px;
}

.account-menu-button > ion-icon {
  color: var(--primary-color-main);
  font-size: 1.2rem;
  margin-right: 1rem;
}

@media (min-width: 992px) {
  .account-menu-button {
    display: none;
  }

  #dashboard-main{
    min-width: 0;
  }
}
