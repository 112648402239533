.content {
  height: 100%;
  padding: 30px 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18px;
}

.form1 {
}

.sectionHeader {
  font-size: 21px;
  line-height: 24px;
}

.hint {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 40px;
}

.submitButton {
  margin-top: 20px;
}
