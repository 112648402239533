ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu ion-list {
  padding: 20px 0;
}

ion-menu ion-note {
  margin-bottom: 30px;
}

ion-menu ion-list-header,
ion-menu ion-note {
  padding-left: 10px;
}

ion-menu ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu ion-item.selected {
}

ion-menu ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu ion-item ion-icon {
  color: #616e7e;
}

ion-menu ion-item ion-label {
  font-weight: 500;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--primary-color-main);
}

.dashboard-menu-footer {
  padding: 2rem 2rem;
}

.dashboard-menu-footer:before {
  background-image: none;
}

.dashboard-menu-footer-icons {
  max-width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.dashboard-menu-footer-icons > img {
  width: 30px;
  height: auto;
}

.dashboard-menu-footer-text {
  color: var(--text-color-v3);
  font-size: 0.9rem;
}
