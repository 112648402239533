
.page1 .ion-content-sub-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page1 .content-container .router-outlet {
}

.page1 .content-container {
  display: flex;
  justify-content: center;
}

.page1 .logo-container {
  display: flex;
  justify-content: center;
  margin: 0.75rem 0;
  margin-top: 2.75rem;
}

.page1 .logo-container .logo {
  width: auto;
  height: 2.75rem;
}

.page1 .footer {
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.page1 .sub-footer {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
  text-align: center;
}

.page1 .footer .link,
.page1 .sub-footer .link {
  color: var(--text-color-v2);
  font-size: 1.3rem;
  text-align: center;
}

.onboarding-skip-btn {
  color: var(--text-color-v3);
  border: 1px solid var(--text-color-v3);
  background-color: transparent;
  width: 100%;
  border-radius: 0.25rem !important;
  margin: 1.5rem 0;
}
