.page {
  background-color: #deebfc;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 420px;
}

.logo {
  margin: 50px 0 76px;
  color: #fff;
}

.notFound {
  background-color: #fff;
  border-radius: 8px;
  padding: 36px 42px;
  color: #707070;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.header {
  font-size: 72px;
  font-weight: 400;
  margin-bottom: 54px;
  color: #b2b2b2;
}

.subheader {
  font-size: 21px;
  font-weight: 400;
}

.button {
  margin-top: 54px;
  border-radius: 8px;
  background-color: white;
  border: 2px solid #6b9ef2;
  width: 100%;
  padding: 18px;
  display: block;
  text-align: center;
  color: #6b9ef2;
}
