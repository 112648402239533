.form1 {
  padding: 36px 36px;
  border-radius: 0.5rem;
  background: white;
  color: #3c3c3c;
}

.sectionHeader {
  margin-top: 24px;
  font-size: 14px;
  font-weight: 500;
}

.hint {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

.oldQuestion {
  font-size: 16px;
  margin: 12px 0;
}

.inputGroup {
  padding-top: 4px;
}

.submitButton {
  margin-top: 20px;
}
