.expandable {
  border-radius: 8px;
  color: #ffffff;
  background-color: #6b9ef2;
  cursor: pointer;
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
  padding: 0 16px 20px;
}

.expanded {
  cursor: initial;
  color: initial;
  background-color: initial;
  border: 1px solid #cecece;
}
