.amountTitle {
  color: var(--text-color-v1);
  font-weight: 500;
}

.label {
  color: var(--text-color-v3);
  padding-left: 0;
}

.value {
  color: var(--text-color-v1);
  text-align: right;
}

.totalsRow {
  margin-top: auto;
  padding-top: 1.75rem;
  border-top: 1px solid var(--text-color-v5);

  display: flex;
  align-items: center;
}

.feeRow {
  padding-top: 1.75rem;
}

.totalsLabel {
  color: var(--text-color-v1);
  font-weight: 400;
  font-size: 1.2rem;
}

.totalsValue {
  color: var(--text-color-v1);
  font-size: 1.75rem;
}

.currencyLabel {
  font-size: 1.2rem;
}

.transferStage {
  padding: 0rem;
  height: 100%;
}

.summaryCol,
.paymentDetailsCol {
  padding: 1.75rem 2rem;
}

.paymentDetailsCol {
  border-right: 1px solid var(--text-color-v5);
}

.contentGrid {
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 100px);
}
