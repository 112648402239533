.infoBox {
  padding: 2rem 2rem;
  border: 1px solid var(--text-color-v5);
  padding-bottom: 4rem;
}

.infoBoxTitle {
  color: var(--text-color-v4);
}

.infoBoxDetail {
  color: var(--text-color-v0);
  font-weight: 500;
}

.addRecipientBtn {
  color: var(--secondary-color-v3);
  background: white;
  font-size: 1rem;
  max-width: 250px;
  padding: 1.5rem;
  text-align: left;
}
