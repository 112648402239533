.phoneInputContainer {
  width: 100%;
}

.phoneInputContainer input {
  user-select: text; /* supported by Chrome and Opera */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text;

  border: none;
  background: inherit;
  width: 100%;
  outline: none;
}
