.iconContainer {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  margin: 3rem 0;
  text-align: center;
  font-size: 1.4rem;
}

.footerMessage {
  text-align: center;
  line-height: 1.5rem;
}

.icon {
}
