.passwordInput {
  width: 100%;
}

.strengthGrid {
  font-size: 0.9rem;
}

.strengthGrid ion-col {
  display: flex;
  align-items: center;
}

.strengthGrid ion-col > ion-icon {
  margin-right: 0.5rem;
  font-size: 1.4em;
}

.validSection {
  color: #2cb117;
}

.invalidSection {
  color: var(--text-color-v4);
}

.criteriaHeader {
  color: initial !important;
}
